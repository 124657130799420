<template lang="">
  <div class="min-h-screen">
    <Navbar />
    <div class="h-18"></div>
    <ContactMain />
    <Footer />
  </div>
</template>
<script>
import Vue from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import ContactMain from "@/components/ContactMain.vue";

export default Vue.extend({
  name: "Dashboard",
  components: { Navbar, Footer, ContactMain },
  data() {
    return {};
  },
});
</script>
<style lang="scss"></style>
