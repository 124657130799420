<template lang="">
  <div id="after-main-wrapper" class="mt-8">
    <div
      class="small-line max-w-screen-xl mx-auto mb-4"
      style="border-top: 2px solid var(--accent-color);"
    ></div>
    <div id="eng" class="max-w-screen-lg mx-auto flex mb-4">
      <img :src="flag" alt="british flag" class="w-16 h-16 rounded-full" />
      <h3
        class="text-center my-auto text-xl font-semibold ml-4"
        style="color: var(--accent-color)"
      >
        I also offer therapy sessions in English. Feel free to contact me for
        any further information.
      </h3>
    </div>
    <div
      class="small-line max-w-screen-xl mx-auto"
      style="border-top: 2px solid var(--accent-color); margin-bottom: 72px;"
    ></div>
  </div>
</template>
<script lang="js">
import flag from "@/assets/pictures/flag-en.png";
export default {
  data() {
    return {
      flag,
    };
  },
};
</script>
<style lang="scss">
.small-line {
  width: calc(100vw * 0.8);
  max-width: 1280px;
}
@media (max-width: 1350px) {
  #eng {
    display: flex;
    max-width: 80%;
    margin: 0 auto 1rem auto;
  }
}
@media (max-width: 750px) {
  #after-main-wrapper {
    padding-top: 1rem;
  }
  #eng {
    margin: 0 auto 1rem auto;
  }
  .small-line {
    margin-top: 66px;
    width: 90%;
    margin: 0 1rem 0 1rem;
  }
}
@media (max-width: 380px) {
  #after-main-wrapper {
    padding-top: 1rem;
  }
  #eng {
    h3 {
      font-size: 0.825rem;
    }
  }
}
</style>
