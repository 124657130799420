<template lang="">
  <div class="min-h-screen">
    <Navbar />
    <!-- <div class="h-20"></div> -->
    <TherapyHero />
    <TherapyMain />
    <Footer />
  </div>
</template>
<script>
import Vue from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import TherapyHero from "@/components/TherapyHero.vue";
import TherapyMain from "@/components/TherapyMain.vue";

export default Vue.extend({
  name: "Dashboard",
  components: { Navbar, Footer, TherapyHero, TherapyMain },
  data() {
    return {
      isTrue: true,
    };
  },
});
</script>
<style lang=""></style>
