<template lang="">
  <div class="flex justify-center items-center bg-gray-700 text-gray-300 h-20">
    <ul class="flex space-x-8">
      <li><router-link to="/privacy">Datenschutz</router-link></li>
      <li><router-link to="/imprint">Impressum</router-link></li>
    </ul>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss"></style>
