<template lang="">
  <div class="min-h-screen">
    <Navbar />
    <div class="h-18"></div>
    <AboutMain />
    <Footer />
  </div>
</template>
<script>
import Vue from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import AboutMain from "@/components/AboutMain.vue";

export default Vue.extend({
  name: "Dashboard",
  components: { Navbar, Footer, AboutMain },
  data() {
    return {};
  },
});
</script>
<style lang="scss"></style>
