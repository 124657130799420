<template lang="">
  <div>
    <div
      id="hero-container"
      class="w-full h-screen"
      :style="{
        'background-image': 'url(' + header + ')',
      }"
    >
      <div id="welcome" class="grid grid-cols-2 h-full border">
        <div></div>
        <div class="text-white my-auto">
          <h1 id="hero-heading" class="text-6xl font-semoibold">
            <strong class="uppercase">Willkommen</strong
            ><span class="text-4xl"> in der</span><br />
            <span id="praxis" style="font-family: 'Parisienne', cursive"
              >Praxis Linda Dendtel</span
            >
          </h1>
          <h2 id="hero-subheading" class="mt-4 mb-1 text-3xl font-semibold">
            Ganzheitliche Psychotherapie
          </h2>
          <h4 id="sub-sub-heading" class="mb-4">
            nach dem Heilpraktikergesetz
          </h4>
          <button
            @click="$router.push('/contact')"
            class="py-4 px-12 uppercase font-semibold rounded-sm mt-4"
          >
            Kontakt
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import header from "@/assets/pictures/header.png";
export default {
  data() {
    return {
      header,
    };
  },
};
</script>
<style lang="scss">
#hero-container {
  background-size: cover;
  #hero-heading {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
    strong {
      font-weight: 800;
      font-size: 4.25rem;
    }
  }
  #hero-subheading {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  }
  #sub-sub-heading {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  }
  button {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    transition: all 0.2s linear;
    letter-spacing: 2px;
    background-color: var(--accent-color);
  }
  button:hover {
    letter-spacing: 5px;
  }
  #praxis {
    font-weight: 500;
  }
}
@media (max-width: 1550px) {
  #welcome {
    grid-template-columns: 1fr 2fr;
  }
}
@media (max-width: 940px) {
  #welcome {
    grid-template-columns: 1fr 4fr 1fr;
  }
}
@media (max-width: 900px) {
  #welcome {
    grid-template-columns: 1fr 8fr 1fr;
  }
}
@media (max-width: 750px) {
  #welcome {
    grid-template-columns: 1fr 8fr 1fr;
    #hero-heading {
      font-size: 2rem;
      strong {
        font-size: 2.5rem;
      }
      span {
        font-size: 1.25rem;
      }
      #praxis {
        font-size: 3rem;
      }
    }
    #hero-subheading {
      font-size: 1.25rem;
    }
  }
}
@media (max-width: 450px) {
  #welcome {
    #hero-heading {
      span {
        display: block;
      }
    }
  }
}
@media (max-width: 330px) {
  #welcome {
    #hero-heading {
      font-size: 1.5rem;
      strong {
        font-size: 2rem;
      }
      span {
        font-size: 1.125rem;
      }
      #praxis {
        font-size: 2.25rem;
      }
    }
    #hero-subheading {
      font-size: 1.125rem;
    }
  }
}
</style>
