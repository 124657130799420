<template>
  <div
    class="pt-32"
    id="contact-main"
    :style="{
      'background-image': 'url(' + sky + ')',
    }"
    style="background-size: cover"
  >
    <div class="max-w-screen-lg mx-auto">
      <h2 class="text-3xl font-semibold" style="color: var(--accent-color)">
        Kosten
      </h2>
      <div
        class="w-14 my-6 mt-4 mb-10"
        style="border-bottom: 2px solid var(--accent-color)"
      ></div>
      <p><strong>Dauer</strong>: 90 Minuten</p>
      <p><strong>Honorar</strong>: 150 Euro</p>
      <p class="mt-4">
        Die Kosten für die Therapiesitzungen werden leider nicht von den
        gesetzlichen Krankenkassen getragen. Aus diesem Grund ist das Honorar an
        mich privat zu entrichten.
      </p>
      <h2
        class="text-3xl font-semibold mt-16"
        style="color: var(--accent-color)"
      >
        Ablauf
      </h2>
      <div
        class="w-14 my-6 mt-4 mb-10"
        style="border-bottom: 2px solid var(--accent-color)"
      ></div>
      <p class="mt-4">
        Momentan gibt es eine Warteliste. Wenn du Interesse an einer Therapie
        bei mir hast, dann schreib mir gerne eine Email, dass du auf die
        Warteliste möchtest. Ich antworte dir dann mit der Bestätigung auf
        deinen Wartelistenplatz.
      </p>
      <!-- <p>
        Wenn es soweit ist, dass ich dir einen Platz anbieten kann, können wir
        ein telefonisches Vorgespräch führen. Im Nachgang bekommst du eine Email
        mit der Bestätigung zu deinem Ersttermin und einen Therapiefragebogen
        zum Ausfüllen, für dich und mich zur Vorbereitung.
      </p> -->
      <p class="mt-2">
        (Bitte keine Anrufe und keine AB-Nachrichten. Da habe ich momentan nicht
        die Kapazitäten für. Ich danke dir!)
      </p>
      <!-- <div
        class="calendly-inline-widget"
        data-url="https://calendly.com/linda-dendtel"
        style="min-width:320px;height:700px;"
      ></div> -->
    </div>
    <div class="max-w-screen-lg mx-auto pb-4">
      <h1
        class="text-3xl font-semibold mt-16"
        style="color: var(--accent-color)"
      >
        Kontakt
      </h1>
      <div
        class="w-14 my-6 mt-4 mb-10"
        style="border-bottom: 2px solid var(--accent-color)"
      ></div>
      <p class="font-semibold">Praxis Linda Dendtel</p>
      <p class="mt-4">Erich-Köhn-Strasse 88</p>
      <p>04177 Leipzig</p>
      <p class="font-semibold mt-4">
        praxis.dendtel<i class="fas fa-at" style="font-weight: 600;"></i
        >gmail.com
      </p>
      <p class="mt-2">
        (Bitte keine Anrufe und keine AB-Nachrichten. Da habe ich momentan nicht
        die Kapazitäten für. Ich danke dir!)
      </p>
      <p class="mt-6">
        Ich bitte um Verständnis, da ich kein Kontaktformular auf meiner Website
        nutze. Darüber schränke ich die Aktivität von Spam-Bots erheblich ein
        und erleichtere mir das Leben :)
      </p>
    </div>
    <!-- NETZWERK -->
    <div class="max-w-screen-lg mx-auto pb-16">
      <h1
        class="text-3xl font-semibold mt-16"
        style="color: var(--accent-color)"
      >
        Netzwerk
      </h1>
      <div
        class="w-14 my-6 mt-4 mb-10"
        style="border-bottom: 2px solid var(--accent-color)"
      ></div>
      <p class="mt-2">
        Hier findest du Angebote von KollegInnen und AusbilderInnen, die ich
        gerne empfehle.
      </p>
      <!-- Wurzelwerk -->
      <p class="mt-4">
        <strong>Wurzelwerk Leipzig</strong>
      </p>
      <p>Privatpraxis für ganzheitliche Beratung und Psychotherapie</p>
      <p>Traumatherapie / EMDR Therapie & Coaching</p>
      <a
        class="pb-2"
        target="_blank"
        href="https://www.wurzelwerk-leipzig.de/"
        style="color: var(--accent-color)"
        ><strong>Zur Website wurzelwerk-leipzig.de</strong></a
      >
      <!-- Judith -->
      <p class="mt-6">
        <strong>Judith Hendin</strong>
      </p>
      <p>Conscious Body & Voice Dialogue Institute</p>
      <p>
        Online Trainings, Workshops & Sessions
      </p>
      <a
        class="pb-2"
        target="_blank"
        href="https://www.consciousbody.com/"
        style="color: var(--accent-color)"
        ><strong>Zur Website consciousbody.com</strong></a
      >
      <!-- Andrea -->
      <p class="mt-6">
        <strong>Andrea Naurath</strong>
      </p>
      <p>Voice Dialogue Berlin</p>
      <p>SFacilitator Training & mehr in Berlin / Online</p>
      <a
        class="pb-2"
        target="_blank"
        href="https://www.voice-dialogue-berlin.de/"
        style="color: var(--accent-color)"
        ><strong>Zur Website voice-dialogue-berlin.de</strong></a
      >
    </div>
  </div>
</template>

<script>
import sky from "@/assets/pictures/sky_01.jpg";
export default {
  data() {
    return {
      sky,
    };
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    externalScript.setAttribute("type", "text/javascript");
    document.head.appendChild(externalScript);
  },
};
</script>

<style lang="scss">
@media (max-width: 1050px) {
  #contact-main {
    padding: 8rem 2rem 0 2rem;
  }
}
@media (max-width: 380px) {
  #contact-main {
    h1 {
      font-size: 1rem;
    }
    h2 {
      font-size: 1rem;
    }
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 0.75rem;
    }
  }
}
</style>
