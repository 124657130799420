<template lang="">
  <div class="text-lg">
    <!-- PART 1 -->
    <div id="th-main-1" class="grid mx-auto mt-8">
      <div id="th-main-1-content" class="ml-4">
        <h3 class="text-3xl font-semibold">
          Körper & Geist als Einheit
        </h3>
        <div
          class="w-24 my-6 mt-4 mb-10"
          style="border-bottom: 2px solid var(--accent-color)"
        ></div>
        <p class="">
          In der Körperpsychotherapie entdecken wir gemeinsam das Wissen des
          Körpers - die Erinnerung an Vergangenes, das Gesehen werden möchte, um
          sich zu lösen. Es geht darum eine Brücke zwischen Psyche und Körper
          wieder aufzubauen, die einst verschüttet gegangen ist. In der Einheit
          von Körper und Geist haben wir wieder Zugang zu unseren Ressourcen,
          Bedürfnissen, unserer Intuition und dem authentischen Selbstausdruck.
          Somatische Impulse, Gedanken und Gefühle werden allesamt im Rahmen der
          Therapie betrachtet und ermöglichen tiefe Einsichten in die Traumata
          und Verletzungen sowie Schutzmechanismen, wodurch nachhaltige
          Veränderung möglich wird.
        </p>
        <p class="font-semibold mt-8 quote" style="color: var(--accent-color)">
          "In order to change, people need to become aware of their sensations
          and the way that their bodies interact with the world around them.
          Physical self-awareness is the first step in releasing the tyranny of
          the past." - Bessel A. van der Kolk, The Body Keeps the Score: Brain,
          Mind, and Body in the Healing of Trauma
        </p>
      </div>
      <img
        :src="woods4"
        alt=""
        class="mx-auto my-auto object-cover w-full h-full"
      />
    </div>
    <div class="divider"></div>
    <!-- PART 2 -->
    <div
      id="th-main-2"
      class="w-full bg-cover mt-16 grid"
      :style="{
        'background-image': 'url(' + women1 + ')',
      }"
      style="grid-template-columns: 1fr 1fr;"
    >
      <div id="empty"></div>
      <div id="th-main-2-content" class="text-white m-8 p-8">
        <h3
          class="text-3xl font-semibold mt-4 text-white"
          style="text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);"
        >
          Werde lebendig
        </h3>
        <div
          class="w-24 my-6 mt-4 mb-10"
          style="border-bottom: 2px solid var(--accent-color)"
        ></div>
        <p class="">
          Das, was oft Jahrzehntelang verkrampft war, kommt durch Elemente des
          Lebendigen wie den Atem, Bewegung und Stimmausdruck, in einen Prozess
          natürlicher Entfaltung. Schutzschichten werden achtsam Schicht für
          Schicht abgetragen, sodass sich Gefühle von tiefer Trauer, Wut und
          Angst endlich zeigen dürfen. Durch körperpsychotherapeutische Übungen,
          Interventionen und sanfte Berührungen kann sich ausdrücken und
          entladen, was sich lange aufgestaut hat. Die Lebenskraft, die dadurch
          wieder ungehinderter fließen kann, bringt oft Kreativität,
          Verspieltheit, Freude, Kraft und unbändige Lebenslust zurück in den
          Alltag.
        </p>
        <h3
          class="text-3xl font-semibold mt-16 text-white"
          style="text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);"
        >
          Im Vertrauen sein
        </h3>
        <div
          class="w-24 my-6 mt-4 mb-10"
          style="border-bottom: 2px solid var(--accent-color)"
        ></div>
        <p class="">
          Dabei ist die vertrauensvolle Beziehung zwischen mir als Therapeutin
          und dir als KlientIn essentiel. Das gesamte Leben ist Beziehung: Zu
          dir selbst oder zu anderen. Im geschützten Rahmen der Therapie darf
          eine neue Form des Begegnens erlebt werden, in dem nicht abgewertet
          oder verurteilt wird. Du bist als Mensch willkommen, genauso wie du
          bist!
        </p>
      </div>
    </div>
    <div class="divider"></div>
    <!-- PFFFF PART -->
    <div id="pffff" class="mx-auto px-8">
      <h3 class="text-3xl font-semibold">
        Körper in der Traumatherapie
      </h3>
      <div
        class="my-6 mt-6 mb-8"
        style="border-bottom: 2px solid var(--accent-color)"
      ></div>
      <p class="">
        Die Forschung der letzten Jahrzehnte weisen immer mehr daraufhin, dass
        das was Wilhelm Reich in der Abspaltung von der freudianischen
        Vorgehensweise einst als körperorientierte Psychotherapie begründet hat,
        den richtigen Riecher hatte: Wir brauchen den Körper für eine wirklich
        tiefgreifende Traumatherapie! Alexander Lowen, Bessel van der Kolk,
        Peter Levine und einige andere haben dazu spannende Entdeckungen gemacht
        und stützen und prägen damit fortlaufend therapeutische Perspektiven.
      </p>
    </div>
    <!-- PART 3 -->
    <div id="th-main-3" class="grid mx-auto my-8">
      <img
        :src="women2"
        alt=""
        class="mx-auto my-auto object-cover w-full h-full"
      />
      <div id="th-main-3-content" class="ml-4">
        <div id="pffff-2">
          <h3 class="text-3xl font-semibold">
            Körper in der Traumatherapie
          </h3>
          <div
            class="w-24 my-6 mt-4 mb-10"
            style="border-bottom: 2px solid var(--accent-color)"
          ></div>
          <p class="">
            Die Forschung der letzten Jahrzehnte weist immer mehr darauf hin,
            dass das, was Wilhelm Reich in der Abspaltung von der freudianischen
            Vorgehensweise einst als körperorientierte Psychotherapie begründet
            hat, den richtigen Riecher hatte: Wir brauchen den Körper für eine
            wirklich tiefgreifende Traumatherapie! Alexander Lowen, Bessel van
            der Kolk, Peter Levine und einige andere haben dazu spannende
            Entdeckungen gemacht und stützen und prägen damit fortlaufend
            therapeutische Perspektiven.
          </p>
        </div>
        <h3 class="text-3xl font-semibold mt-8">
          Körperpsychotherapie für das Individuum & das Kollektiv
        </h3>
        <div
          class="w-24 my-6 mt-4 mb-10"
          style="border-bottom: 2px solid var(--accent-color)"
        ></div>
        <p class="">
          Wenn Menschen in die Therapie gehen, um ihre alten Wunden zu heilen,
          so tun sie sich selsbt etwas Gutes. Sie kommen in ihre eigene Kraft
          und können ganz anders im Leben agieren. Die Erfahrung ist oftmals,
          dass sich plötzlich neue Wege aufzeigen, neue Türen öffenen, von denen
          man nicht einmal wusste, dass sie vorhanden sind. Gleichzeitig scheint
          mit der individuellen Heilung immer auch ein Stück weit kollektive
          Heilung stattzufinden. Um die Welt von Morgen friedlich und liebevoll
          mitgestalten zu können müssen wir die Welt von Gestern heilen.
        </p>
        <br />
        <p>Oder wie der Autor Joseph Heller es so schön formuliert hat:</p>
        <p class="font-semibold mt-8 quote" style="color: var(--accent-color)">
          "The body stores the trauma of our lives in muscular rigidity, thereby
          keeping us stuck in the past. When we release the tension in the body
          and align ourselves with gravity, we take a new stand in life. This
          allows us to be at ease with ourselves and in harmony in our
          relationship to others and to our planet."
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import women1 from "@/assets/pictures/women_dancing.jpg";
import women2 from "@/assets/pictures/women_minified.jpg";
import woods4 from "@/assets/pictures/woods_04.jpg";
export default {
  data() {
    return {
      women1,
      women2,
      woods4,
    };
  },
};
</script>
<style lang="scss">
#pffff {
  display: none;
  border-bottom: 2px solid var(--accent-color);
  max-width: 80%;
  margin-top: 6rem;
  padding: 2rem 0 2rem 0;
}
h3 {
  line-height: 2rem;
  font-weight: 600;
  color: var(--accent-color);
}
.divider {
  display: none;
}
#th-main-1 {
  grid-template-columns: 3fr 4fr;
  #th-main-1-content {
    min-width: 600px;
    padding: 2rem;
    .quote {
      padding: 0 2rem 0 2rem;
    }
  }
}
#th-main-2 {
  #th-main-2-content {
    min-width: 800px;
  }
}
#th-main-3 {
  grid-template-columns: 2fr 3fr;
  #th-main-3-content {
    min-width: 1000px;
    padding: 2rem;
    .quote {
      padding: 0 2rem 0 2rem;
    }
  }
  img {
    max-height: 730px;
  }
}
@media (max-width: 1350px) {
  #th-main-3 {
    grid-template-columns: 1fr 1fr;
    #th-main-3-content {
      min-width: 100px;
      padding: 2rem;
      .quote {
        padding: 0 2rem 0 2rem;
      }
    }
  }
  #pffff {
    display: block;
  }
  #pffff-2 {
    display: none;
  }
}
@media (max-width: 1279px) {
  #pffff {
    display: none;
  }
  #pffff-2 {
    display: block;
  }
  .divider {
    display: block;
    width: 80%;
    border-bottom: 2px solid var(--accent-color);
    margin: 4rem auto 3rem auto;
  }
  #th-main-1 {
    grid-template-columns: 1fr;
    #th-main-1-content {
      min-width: 1rem;
      padding: 1rem;
      .quote {
        padding: 0;
        margin-bottom: 2rem;
      }
    }
    img {
      max-height: 24rem;
    }
  }
  #th-main-2 {
    display: block;
    #empty {
      display: none;
    }
    #th-main-2-content {
      margin: 0;
      width: 100vw;
      min-width: 1rem;
      padding-right: 2rem;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  #th-main-3 {
    margin-top: 4rem;
    grid-template-columns: 1fr;
    img {
      max-height: 48rem;
    }
    #th-main-3-content {
      min-width: 1rem;
      padding: 1rem;
      .quote {
        padding: 0;
      }
    }
  }
}
@media (max-width: 380px) {
  #th-main-1 {
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 0.75rem;
    }
  }
  #th-main-2 {
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 0.75rem;
    }
  }
  #th-main-3 {
    h3 {
      padding-top: 1rem;
      font-size: 1rem;
    }
    p {
      font-size: 0.75rem;
    }
  }
}
</style>
