<template lang="">
  <div>
    <!-- DESKTOP -->
    <div
      id="therapy-hero"
      class="w-full"
      :style="{
        'background-image': 'url(' + woods3 + ')',
      }"
    >
      <div id="therapy-hero-grid" class="grid grid-cols-2 h-full border">
        <div class="text-white my-auto text-right">
          <h1 id="therapy-heading" class="pl-4 text-7xl font-bold">
            Körperpsychotherapie<br /><span class="text-6xl"
              >als Weg zu dir selbst</span
            >
          </h1>
          <button
            id="therapy-hero-button"
            @click="$router.push('/contact')"
            class="py-4 px-12 uppercase font-semibold rounded-sm mt-8"
          >
            Jetzt starten!
          </button>
        </div>
      </div>
    </div>
    <!-- MOBILE -->
    <div
      id="therapy-hero-mobile"
      class="w-full"
      :style="{
        'background-image': 'url(' + woods3Mobile + ')',
      }"
    >
      <div id="therapy-hero-grid" class="grid grid-cols-2 h-full border">
        <div class="text-white my-auto text-right">
          <h1 id="therapy-heading" class="pl-4 text-7xl font-bold">
            Körperpsychotherapie<br /><span class="text-6xl"
              >als Weg zu dir selbst</span
            >
          </h1>
          <button
            id="therapy-hero-button"
            @click="$router.push('/contact')"
            class="py-4 px-12 uppercase font-semibold rounded-sm mt-8"
          >
            Jetzt starten!
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import woods3 from "@/assets/pictures/woods_03_minified.jpg";
import woods3Mobile from "@/assets/pictures/woods_03_minified_mobile.jpg";
export default {
  data() {
    return {
      woods3,
      woods3Mobile,
    };
  },
};
</script>
<style lang="scss">
#therapy-hero-mobile {
  display: none;
  background-size: cover;
  height: 100vh;
  #therapy-heading {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
  }
  button {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    transition: all 0.2s linear;
    letter-spacing: 2px;
    background-color: var(--accent-color);
  }
  button:hover {
    letter-spacing: 5px;
  }
}
#therapy-hero {
  background-size: cover;
  height: 100vh;
  #therapy-heading {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
  }
  button {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    transition: all 0.2s linear;
    letter-spacing: 2px;
    background-color: var(--accent-color);
  }
  button:hover {
    letter-spacing: 5px;
  }
}
@media (max-width: 1550px) {
  #therapy-hero {
    #therapy-hero-grid {
      grid-template-columns: 2fr 1fr;
      #therapy-heading {
        font-size: 3.5rem;
        span {
          font-size: 2.5rem;
        }
      }
    }
  }
}
@media (max-width: 1050px) {
  #therapy-hero {
    #therapy-hero-grid {
      grid-template-columns: 3fr 1fr;
      #therapy-heading {
        font-size: 3rem;
        span {
          font-size: 2rem;
        }
      }
    }
  }
}
@media (max-width: 750px) {
  #therapy-hero {
    display: none;
  }
  #therapy-hero-mobile {
    display: block;
    #therapy-hero-grid {
      display: flex;
      justify-content: center;
      #therapy-heading {
        font-size: 2.5rem;
        span {
          font-size: 1.75rem;
        }
      }
      button {
        font-size: 0.75rem;
      }
    }
  }
}
@media (max-width: 500px) {
  #therapy-hero {
    display: none;
  }
  #therapy-hero-mobile {
    #therapy-hero-grid {
      display: flex;
      justify-content: center;
      #therapy-heading {
        font-size: 2rem;
        span {
          font-size: 1.25rem;
        }
      }
      button {
        font-size: 0.5rem;
      }
    }
  }
}
@media (max-width: 400px) {
  #therapy-hero {
    display: none;
  }
  #therapy-hero-mobile {
    #therapy-hero-grid {
      display: flex;
      justify-content: center;
      #therapy-heading {
        font-size: 1.25rem;
        span {
          font-size: 1rem;
        }
      }
      button {
        font-size: 0.5rem;
        padding: 0.5rem 1rem 0.5rem 1rem;
      }
    }
  }
}
</style>
