<template lang="">
  <div class="fixed top-0 w-full h-20 z-50">
    <!-- class="flex justify-center h-full bg-white z-50" -->
    <div
      id="navbar-desktop"
      class="grid grid-cols-2 bg-white z-50 h-16"
      style="border-bottom: 2px solid var(--accent-color); color: var(--accent-color);"
    >
      <div class="flex jsutify-center items-center">
        <img class="h-12 mx-auto" :src="logo2" alt="" />
      </div>
      <ul
        class="flex items-center h-full space-x-8 font-semibold tracking-wider"
      >
        <li>
          <router-link
            class="link"
            :class="$route.path == '/' ? 'active' : ''"
            to="/"
            >Home</router-link
          >
        </li>
        <li>
          <router-link
            class="link"
            to="/therapy"
            :class="$route.path == '/therapy' ? 'active' : ''"
            >Körperpsychotherapie</router-link
          >
        </li>
        <li>
          <router-link
            class="link"
            :class="$route.path == '/about' ? 'active' : ''"
            to="/about"
            >Über mich</router-link
          >
        </li>
        <li>
          <router-link
            class="link"
            :class="$route.path == '/contact' ? 'active' : ''"
            to="/contact"
            >Kosten & Kontakt</router-link
          >
        </li>
      </ul>
    </div>
    <!-- navbar tablet -->
    <div
      id="navbar-tablet"
      class="bg-white z-50 flex justify-evenly h-16"
      style="border-bottom: 2px solid var(--accent-color); color: var(--accent-color); height: 66px;"
    >
      <div class="flex jsutify-center items-center">
        <img class="h-12 mx-auto" :src="logo2" alt="" />
      </div>
      <ul
        class="flex items-center h-full space-x-8 font-semibold tracking-wider"
      >
        <li>
          <router-link
            class="link"
            :class="$route.path == '/' ? 'active' : ''"
            to="/"
            >Home</router-link
          >
        </li>
        <li>
          <router-link
            class="link"
            to="/therapy"
            :class="$route.path == '/therapy' ? 'active' : ''"
            >Körperpsychotherapie</router-link
          >
        </li>
        <li>
          <router-link
            class="link"
            :class="$route.path == '/about' ? 'active' : ''"
            to="/about"
            >Über mich</router-link
          >
        </li>
        <li>
          <router-link
            class="link"
            :class="$route.path == '/contact' ? 'active' : ''"
            to="/contact"
            >Kosten & Kontakt</router-link
          >
        </li>
      </ul>
    </div>
    <!-- navbar mobile -->
    <div
      id="navbar-mobile"
      class="bg-white z-50 relative"
      style="border-bottom: 2px solid var(--accent-color); color: var(--accent-color); height: 66px;"
    >
      <div class="flex px-4 w-full justify-between">
        <div class="flex jsutify-center items-center">
          <img class="h-12 mx-auto" :src="logo2" alt="" />
        </div>
        <div class="flex jsutify-center items-center">
          <i
            class="fas fa-bars text-4xl cursor-pointer"
            @click="showMobileList = !showMobileList"
          ></i>
        </div>
      </div>
      <!-- mobile list -->
      <div v-if="showMobileList" class="absolute top-14 bg-white w-full">
        <ul
          class="flex-col items-center h-full font-semibold tracking-wider space-y-4 py-4"
          style="border-top: 2px solid var(--accent-color);border-bottom: 2px solid var(--accent-color); color: var(--accent-color)"
        >
          <li class="flex justify-center">
            <router-link
              class="link"
              :class="$route.path == '/' ? 'active' : ''"
              to="/"
              >Home</router-link
            >
          </li>
          <li class="flex justify-center">
            <router-link
              class="link"
              to="/therapy"
              :class="$route.path == '/therapy' ? 'active' : ''"
              >Körperpsychotherapie</router-link
            >
          </li>
          <li class="flex justify-center">
            <router-link
              class="link"
              :class="$route.path == '/about' ? 'active' : ''"
              to="/about"
              >Über mich</router-link
            >
          </li>
          <li class="flex justify-center">
            <router-link
              class="link"
              :class="$route.path == '/contact' ? 'active' : ''"
              to="/contact"
              >Kosten & Kontakt</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import logo from "@/assets/logo.png";
import logo2 from "@/assets/pictures/7.png";
export default {
  data() {
    return {
      logo,
      showMobileList: false,
      logo2,
    };
  },
  mounted() {
    window.addEventListener("resize", this.checkForWidth);
    this.checkForWidth();
  },
  methods: {
    checkForWidth() {
      if (window.innerWidth > 1151) {
        this.showMobileList = false;
        document.getElementById("navbar-desktop").style.display = "grid";
        document.getElementById("navbar-tablet").style.display = "none";
        document.getElementById("navbar-mobile").style.display = "none";
      } else if (window.innerWidth > 751) {
        this.showMobileList = false;
        document.getElementById("navbar-desktop").style.display = "none";
        document.getElementById("navbar-tablet").style.display = "flex";
        document.getElementById("navbar-mobile").style.display = "none";
      } else {
        document.getElementById("navbar-desktop").style.display = "none";
        document.getElementById("navbar-tablet").style.display = "none";
        document.getElementById("navbar-mobile").style.display = "flex";
      }
    },
  },
};
</script>
<style scoped lang="scss">
@media (max-width: 1281px) {
  #navbar-desktop {
    grid-template-columns: 1fr 2fr;
  }
}
.link {
  text-decoration: none;
  //transition: all 0.3s linear;
  position: relative;
}
.link:hover {
  color: #222;
}
.active {
  //border-bottom: 2px solid rgb(148, 117, 32);
  color: black;
}
</style>
