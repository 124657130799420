<template lang="">
  <div class="mt-16">
    <div id="starting-text">
      <h2
        class="mx-auto max-w-screen-xl text-3xl font-bold mb-8"
        style="color: var(--accent-color)"
      >
        Was ist ganzheitliche Psychotherapie?
      </h2>
      <div
        class="flex max-w-screen-xl mx-auto text-lg py-8"
        style="border-top: 2px solid var(--accent-color); border-bottom: 2px solid var(--accent-color)"
      >
        <p class="p-2">
          In der ganzheitlichen Psychotherapie arbeite ich, anders als in der
          reinen Gesprächstherapie, auf Basis der
          <router-link
            class="link"
            to="/therapy"
            style="color: var(--accent-color)"
            >Körperpsychotherapie</router-link
          >
          mit Bewegung, Atem, Stimm- und Gefühlsausdruck. Du kommt mit dir
          selbst und deinen innersten verschütteten Anteilen in tiefen Kontakt,
          kannst bergen, was lange verborgen war und damit Raum für Neues in dir
          und aus dir heraus schaffen. Manchmal gehen wir in intensive
          Emotionen, manchmal hat alles einen eher spielerischen Charakter und
          das integrierende Gespräch über die gemachten Erfahrungen in der
          Therapie ist stets ein begleitender Teil.
        </p>
        <p class="p-2">
          Durch die Einbeziehung des Körpers, können nachhaltige Veränderungen
          entstehen, die auf rein kognitiver Ebene nicht stattfinden können. Wir
          sind Kopf und Körper und brauchen daher beide Aspekte unseres Seins in
          der Therapie. Dabei ist der Körper in ganz besonderer Weise unser
          Verbündeter auf dem Weg zu uns selbst und in die Heilung. Er hält für
          uns alle Schlüssel bereit, allen Schmerz alter Erfahrungen, aber auch
          die Leichtigkeit, Freude und Neugierde der Kindheit. Gerade da der
          Körper, anders als der Geist, nicht manipulierbar ist, kann er uns der
          aufrichtigste Wegweiser sein.
        </p>
      </div>
    </div>
    <div id="grid-section" class="grid mt-8">
      <div
        class="flex-col text-lg place-items-center text-gray-900 my-auto therapy-list"
      >
        <h3 class="">
          Therapeutisch begleite ich zum Beispiel bei
        </h3>
        <div
          class="w-24 my-6 mt-4 mb-10"
          style="border-bottom: 2px solid var(--accent-color)"
        ></div>
        <div class="flex">
          <ul class="list-disc pl-6 space-y-2">
            <li>Depressionen, Ängsten & Panik</li>
            <li>Lebenskrisen, Trauer & Verlust, Trennung</li>
            <li>Emotionalen Blockaden</li>
            <li>Fehlende/r Selbstakzeptanz / Selbstliebe</li>
            <li>Problemen bei Grenzziehung</li>
            <li>Beziehungsproblemen</li>
            <li>Bindungstrauma & Bindungsschwierigkeiten</li>
            <li>Fehlender Fähigkeit zur Selbstregulation</li>
            <li>Andauerndem Stress & Überforderung im Alltag</li>
            <li>konstanter Arbeitsbelastung & Burnout</li>
            <li>Genereller Müdigkeit & Abgeschlagenheit</li>
            <li>Psychosomatischen Beschwerden</li>
          </ul>
        </div>
      </div>
      <img
        id="firstPic"
        :src="sittingGirl"
        alt="girl sitting on beach"
        class="w-full object-cover my-auto list-img"
        style="height: 85%"
      />
      <img
        id="firstPicMobile"
        :src="sittingGirlMobile"
        alt="girl sitting on beach"
        class="w-full object-cover my-auto list-img"
        style="height: 85%"
      />
      <img
        id="pic-before"
        :src="standingGirl"
        alt="girl standing on beach arms up"
        style="height: 85%"
        class="w-full object-cover my-auto list-img"
      />
      <div
        class="flex-col text-lg place-items-center text-gray-900 my-auto therapy-list"
      >
        <h3 class="">
          Typische Veränderungen durch die Therapie können sein
        </h3>
        <div
          class="w-24 my-6 mt-4 mb-10"
          style="border-bottom: 2px solid var(--accent-color)"
        ></div>
        <div class="flex space-x-4">
          <ul class="list-disc pl-6 space-y-2">
            <li>mehr Energie im Alltag</li>
            <li>generell mehr Lebensfreude</li>
            <li>Spüren und Ziehen eigener Grenzen</li>
            <li>Intuition / Bauchgefühl wird immer klarer</li>
            <li>Bedürfnisse werden gefühlt & artikuliert</li>
            <li>wachsende Selbstliebe</li>
            <li>Körper wird konstanter spürbar</li>
            <li>mehr Bewusstheit</li>
            <li>Entspannung und Selbstfürsorge stellen sich ein</li>
            <li>aktive und kraftvolle Beziehungsgestaltung</li>
            <li>aktive und kraftvolle Gestaltung des Lebensweges</li>
            <li>mehr Kreativität & authentischer Selbstausdruck</li>
          </ul>
        </div>
      </div>
      <img
        id="pic-after"
        :src="standingGirlMobile"
        alt="girl standing on beach arms up"
        style="height: 85%"
        class="w-full object-cover my-auto list-img"
      />
    </div>
  </div>
</template>
<script>
import sittingGirl from "@/assets/pictures/women_on_beach.jpg";
import standingGirl from "@/assets/pictures/women_on_beach_3.jpg";
import sittingGirlMobile from "@/assets/pictures/women_on_beach_mobile.jpg";
import standingGirlMobile from "@/assets/pictures/women_on_beach_3_mobile.jpg";
import header from "@/assets/pictures/header.png";

export default {
  data() {
    return {
      sittingGirl,
      standingGirl,
      standingGirlMobile,
      sittingGirlMobile,
      header,
      string:
        "https://tarot-clarity-coaching.com/wp-content/uploads/2020/08/jackson-david-WUydTfaP3t4-unsplash.jpg",
    };
  },
};
</script>
<style scoped lang="scss">
#grid-section {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  .therapy-list {
    padding-left: 4rem;
    h3 {
      font-size: 1.875rem;
      line-height: 2rem;
      font-weight: 600;
    }
  }
  #pic-after {
    display: none;
  }
  #firstPicMobile {
    display: none;
  }
}
@media (max-width: 1450px) {
  #starting-text {
    h2 {
      max-width: 80%;
    }
    div {
      max-width: 80%;
    }
  }
}
@media (max-width: 1150px) {
  #starting-text {
    div {
      flex-direction: column;
    }
  }
  #grid-section {
    .therapy-list {
      padding: 0 1.5rem;
      margin: 0 auto;
      font-size: 1rem;
      h3 {
        font-size: 1.25rem;
      }
    }
  }
  #pic-after {
    display: none;
  }
}
@media (max-width: 750px) {
  #starting-text {
    h2 {
      font-size: 1.5rem;
    }
  }
  #grid-section {
    display: block;
    // grid-template-columns: 1fr;
    // //grid-template-rows: repeat(4, 1fr);
    // grid-template-rows: 3fr 2fr 3fr 2fr;
    .list-img {
      height: 100%;
      max-height: 80vh;
      margin: 2rem 0;
    }
    .therapy-list {
      padding: 0 1.5rem;
      font-size: 1rem;
      h3 {
        font-size: 1.25rem;
      }
    }
    #pic-before {
      display: none;
    }
    #pic-after {
      display: block;
    }
    #firstPic {
      display: none;
    }
    #firstPicMobile {
      display: block;
    }
  }
}
@media (max-width: 380px) {
  #starting-text {
    h2 {
      font-size: 1rem;
    }
    p {
      font-size: 0.75rem;
    }
  }
  #grid-section {
    display: block;
    //grid-template-rows: 2fr 1fr 2fr 1fr;
    h3 {
      font-size: 1rem;
    }
    li {
      font-size: 0.75rem;
    }
    .list-img {
      max-height: 50vh;
      margin: 2rem 0;
    }
  }
}
</style>
