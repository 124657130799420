











import Vue from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import DashMain from "@/components/DashMain.vue";
import HeroMain from "@/components/HeroMain.vue";
import AfterMain from "@/components/AfterMain.vue";

export default Vue.extend({
  name: "Dashboard",
  components: { Navbar, Footer, DashMain, HeroMain, AfterMain },
  data() {
    return {
      isTrue: true,
    };
  },
});
